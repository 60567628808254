import React, { Component, createRef } from 'react'
import { Card, Form, Alert } from 'react-bootstrap'
import '../assets/css/Login.css'
import { Link } from "react-router-dom";
import axios from 'axios';
import { store } from '../reduxstore/store';
import { encrypt } from '../reduxstore/cryptoutils';
import Cookies from 'js-cookie';

export class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      setError: "",
      postconfig: {
        withCredentials: true,
        headers: {
          'X-CSRF-Token': ''
        }
      }
    }

    this.emailRef = createRef()
    this.passwordRef = createRef()
  }

  render() {
    return (
      <>
        <div className='d-flex align-items-center justify-content-center bgpagelogin'>
          <div className='logindivcard'>
            <Card style={{ backgroundColor: '#212529', color: 'white', borderRadius: 0 }}>
              <Card.Body>
                <h2 className="text-center mb-4">Login</h2>
                {(this.state.setError !== 'logged' && this.state.setError !== '') && <Alert variant="danger">{this.state.setError}</Alert>}
                {(this.state.setError === 'logged') && <Alert variant="success">Exito</Alert>}
                <Form onSubmit={(e) => { this.handleSubmit(e) }}>
                  <div style={{ margin: '30px 0' }}>
                    <div className="maininput__ctn">
                      <input className="container__input" type="text" id="emailogin" name="emailogin" required ref={this.emailRef}></input>
                      <label htmlFor="emailogin" className="container__label">Email</label>
                    </div>
                  </div>
                  <div style={{ margin: '30px 0' }}>
                    <div className="maininput__ctn">
                      <input className="container__input" type="password" id="psswordlogin" name="psswordlogin" required ref={this.passwordRef}></input>
                      <label htmlFor="psswordlogin" className="container__label">Password</label>
                    </div>
                  </div>
                  <div style={{ marginTop: '40px' }}>
                    <button style={{ background: '#EDF5FC', color: '#000000' }} className="button-glitch w-100" type="submit">Login</button>
                  </div>
                </Form>
                <div className="w-100 text-center mt-4" style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', alignItems: 'center' }}>
                  <div>
                    <Link className='linklogin'>Forgot Password?</Link>
                  </div>
                  <div>
                    <Link className='linklogin' to="/signup">Sign Up</Link>
                  </div>
                </div>
                {/*<div>
                  <button onClick={() => { this.test() }}>holo</button>
    </div>*/}
              </Card.Body>
            </Card>
          </div>
        </div>
      </>
    )
  }

  async handleSubmit(e) {
    e.preventDefault();

    try {
      let response = await axios.post(process.env.REACT_APP_API_URL + '/auth/login', {
        user_email: this.emailRef.current.value,
        user_password: this.passwordRef.current.value
      }, this.state.postconfig);
      let uwu = await encrypt(response.data)
      Cookies.set('udatcl', uwu, { httpOnly: false, expires: 2, sameSite: 'Strict', secure: true });
      store.dispatch({ type: 'SET_USER_DATA', payload: response.data });
      this.setState({ setError: 'logged' });
    } catch (error) {
      if (error.response && error.response.data) {
        if (error.response.data.hasOwnProperty('error')) {
          this.setState({ setError: error.response.data.error });
        } else {
          this.setState({ setError: error });
        }
      } else {
        this.setState({ setError: error.message+' (Server Side Skill Issue)' });
      }   
    }

  }

  /*async test() {
    await this.updatePostConfig()
    console.log(this.state.postconfig)
    try {
      let result = await axios.get(process.env.REACT_APP_API_URL + '/auth/getusers', this.state.postconfig)
      console.log(result)
    } catch (error) {
      console.log(error)
    }
  }*/

  updatePostConfig() {
    return new Promise(resolve => {
      this.setState(prevState => ({
        postconfig: {
          ...prevState.postconfig,
          headers: {
            ...prevState.postconfig.headers,
            'X-CSRF-Token': store.getState().csrfToken || ''
          }
        }
      }), resolve);
    });
  }
}

export default Login