import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbarcomp from './components/Navbarcomp';
import ScrollToTop from './components/ScrollToTop';

import Home from './views/Home';
import Signup from './views/Signup';
import Login from './views/Login';
import Error404 from './views/Error404';
import Aboutme from './views/Aboutme';
import Works from './views/Works';
import AboutmeEN from './views/AboutmeEN';
import HomeV2 from './views/HomeV2';
import ContactMe from './views/ContactMe';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/*Dont ask why not nested route with outlet. Actaally preventing calling the navbar bar everytime main page(Home) is called*/}
    <BrowserRouter>
      <Navbarcomp />
      <ScrollToTop>
        <Routes>
          <Route path="/" exact element={<Home />} />\
          <Route path="/login" exact element={<Login />} />
          <Route path="/signup" exact element={<Signup />} />
          <Route path="/404" exact element={<Error404 />} />
          <Route path="/aboutme" element={<Aboutme />} />
          <Route path="/works" element={<Works />} />
          <Route path="/AboutmeEN" element={<AboutmeEN />} />
          <Route path="/homev2" element={<HomeV2 />} />
          <Route path="/contact" element={<ContactMe />} />
          <Route path="*" element={<Error404 />} />
          {/*<Route path="*" element={<Navigate to ="/404" />}/>*/}
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  </React.StrictMode>
);