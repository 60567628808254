import React, { Component } from 'react';
import '../assets/css/ContactMe.css';
import { Link } from "react-router-dom";

export class ContactMe extends Component {
  render() {
    return (
      <>
        <div className='nav__container'>
          <nav className="contactnav">
            <ul className="nav__list">
              <li className="nav__item" onClick={(e) => { this.switchtab('box1') }}>
                <Link className="nav__link">
                  <div className="nav__thumb nav__color1" nav__data-letter="A" />
                  <p className="nav__label">Box 1</p>
                </Link>
              </li>
              <li className="nav__item" onClick={(e) => { this.switchtab('box2') }}>
                <Link className="nav__link">
                  <div className="nav__thumb nav__color2" nav__data-letter="B" />
                  <p className="nav__label">Box 2</p>
                </Link>
              </li>
              <li className="nav__item" onClick={(e) => { this.switchtab('box3') }}>
                <Link className="nav__link">
                  <div className="nav__thumb nav__color3" nav__data-letter="C" />
                  <p className="nav__label">Box 3</p>
                </Link>
              </li>
              <li className="nav__item" onClick={(e) => { this.switchtab('box4') }}>
                <Link className="nav__link">
                  <div className="nav__thumb nav__color4" nav__data-letter="D" />
                  <p className="nav__label">Box 4</p>
                </Link>
              </li>
              <li className="nav__item" onClick={(e) => { this.switchtab('box5') }}>
                <Link className="nav__link">
                  <div className="nav__thumb nav__color5" nav__data-letter="E" />
                  <p className="nav__label">Box 5</p>
                </Link>
              </li>
              <li className="nav__item" onClick={(e) => { this.switchtab('box6') }}>
                <Link className="nav__link">
                  <div className="nav__thumb nav__color6" nav__data-letter="F" />
                  <p className="nav__label">Box 6</p>
                </Link>
              </li>
            </ul>
          </nav>
          <div className="page">
            <section className="nav__section section--active nav__color1" id='box1'>
              <article className="nav__section__wrapper">
                <h1 className="nav__section__title">Box 1</h1>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>11111111111111.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>ENDD.</p>
              </article>
            </section>
            <section className="nav__section section--hidden nav__color2" id='box2'>
              <article className="nav__section__wrapper">
                <h1 className="nav__section__title">Box 2</h1>
                <p>sadas sad asd sa dsa dsa dsa  dsa dsa dasd asd  das das.</p>
              </article>
            </section>
            <section className="nav__section section--hidden nav__color3" id='box3'>
              <article className="nav__section__wrapper">
                <h1 className="nav__section__title">Box 3</h1>
                <p> asdas das das d sa dsa das d sad as ds sa das das das.</p>
              </article>
            </section>
            <section className="nav__section section--hidden nav__color4" id='box4'>
              <article className="nav__section__wrapper">
                <h1 className="nav__section__title">Box 4</h1>
                <p>dasd sa dsa dsa dsadsdasd sa dsa dsa dsadasdasd asd as d.</p>
              </article>
            </section>
            <section className="nav__section section--hidden nav__color5" id='box5'>
              <article className="nav__section__wrapper">
                <h1 className="nav__section__title">Box 5</h1>
                <p>ad sa dsa d sad sa  sa dsdsadasdas das dsa d asd as das da sda.</p>
              </article>
            </section>
            <section className="nav__section section--hidden nav__color6" id='box6'>
              <article className="nav__section__wrapper">
                <h1 className="nav__section__title">Box 6</h1>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>2222222222222222222222.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>2222222222222222222222.</p>
                <p>Use your 'left' and 'right' arrow keys to navigate.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>sadasd sdasdas dasdsad sadasd sadsadas dasdasda sdasdsadasd asdasdsad sadas.</p>
                <p>2222222222222222222222.</p>
              </article>
            </section>
          </div>
        </div>
      </>
    )
  }

  switchtab(e) {
    let selectedbox = document.querySelector("#" + e);
    let exceptfirst = document.querySelectorAll(".nav__section:not(#" + e + ")");

    selectedbox.classList.remove("section--hidden");
    selectedbox.classList.add("section--active");

    exceptfirst.forEach(sec => {
      sec.classList.remove("section--active");
      sec.classList.add('section--hidden')
    })
  }
}

export default ContactMe