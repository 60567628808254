import React, { Component } from 'react';
import '../assets/css/HomeV2.css';

export class HomeV2 extends Component {
  render() {
    return (
      <div className='hexagonbody'>
        <div>
          <div className='hexagonv2txts'>
            <p>Under Development. When I have time I work on it otherwise I'm just procrastinating on whatever I'm doing. Actually just me lazy as always... Soon on VueJS & AngularJS</p>
          </div>
          <div>
            <ul className='honeycomb'>
              <li className="honeycomb-cell">
                <img className="honeycomb-cell__image" src="https://source.unsplash.com/random/1" alt='' />
                <div className="honeycomb-cell__title">Welcome</div>
              </li>
              <li className="honeycomb-cell">
                <img className="honeycomb-cell__image" src="https://source.unsplash.com/random/300×300/?fruit?sig=1" alt='' />
                <div className="honeycomb-cell__title">About</div>
              </li>
              <li className="honeycomb-cell">
                <img className="honeycomb-cell__image" src="https://source.unsplash.com/random/?city,night" alt='' />
                <div className="honeycomb-cell__title">Services</div>
              </li>
              <li className="honeycomb-cell">
                <img className="honeycomb-cell__image" src="https://source.unsplash.com/random/?developer?sig=1" alt='' />
                <div className="honeycomb-cell__title">Resume/CV</div>
              </li>
              <li className="honeycomb-cell">
                <img className="honeycomb-cell__image" src="https://source.unsplash.com/random/5" alt='' />
                <div className="honeycomb-cell__title">Portafolio</div>
              </li>
              <li className="honeycomb-cell">
                <img className="honeycomb-cell__image" src="https://source.unsplash.com/random/6" alt='' />
                <div className="honeycomb-cell__title">HomeV1</div>
              </li>
              <li className="honeycomb-cell">
                <img className="honeycomb-cell__image" src="https://source.unsplash.com/random/7" alt='' />
                <div className="honeycomb-cell__title">Contact</div>
              </li>
              <li className="honeycomb-cell honeycomb__placeholder"></li>
            </ul>
          </div>

        </div>
      </div>
    )
  }
}

export default HomeV2