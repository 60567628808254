import React, { Component } from 'react'
import '../assets/css/Navbarcomp.css'
import { Nav, Navbar, NavDropdown, Offcanvas, Form, Container } from 'react-bootstrap';
import { Link } from "react-router-dom";
import pandalogo from '../assets/icons/PandaFaceLogo.svg';
import axios from 'axios';
import { store } from '../reduxstore/store';

export class Navbarcomp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsesize: 'lg',
      showchanvas: false,
      bodybrightness: 100
    }
  }

  componentDidMount() {
    this.changesonstorage();
    window.addEventListener("changecolorevent", this.changesonstorage, false);
    this.getsetCSRF();
  }
  componentWillUnmount() {
    window.removeEventListener("changecolorevent", this.changesonstorage, false);
  }

  changesonstorage = () => {
    let test = localStorage.getItem('colormode');

    // eslint-disable-next-line
    if (test == 'lightmode') {
      document.documentElement.style.setProperty('--navbarbackcolor', 'aliceblue');
      document.documentElement.style.setProperty('--navtxtcolor', '#54577C');
      document.documentElement.style.setProperty('--navlogocolor', '#58bcbc');
    } else {
      document.documentElement.style.setProperty('--navbarbackcolor', '#212529');
      document.documentElement.style.setProperty('--navtxtcolor', '#e0e0e0');
      document.documentElement.style.setProperty('--navlogocolor', 'aliceblue');
    }
  }

  closeOffCanvas() {
    this.setState({ showchanvas: false })
  }

  changeColorMode() {
    let test = localStorage.getItem('colormode');

    // eslint-disable-next-line
    if (test == 'lightmode') {
      localStorage.setItem('colormode', 'darkmode');
      window.dispatchEvent(new Event('changecolorevent'))
    } else {
      localStorage.setItem('colormode', 'lightmode');
      window.dispatchEvent(new Event('changecolorevent'))
    }
  }

  changebodybrightness(e) {
    this.setState({ bodybrightness: e })
    document.body.style.setProperty('--bodybrightness', 'brightness(' + e + '%)')
  }

  async getsetCSRF() {
    try {
      let response = await axios.get(process.env.REACT_APP_API_URL + `/auth/csrf`, { withCredentials: true });
      store.dispatch({ type: 'SET_CSRF_TOKEN', payload: response['data']['csrftoken'] });
    } catch (error) {
      console.log(error.message);
    }
  }

  render() {
    return (
      <>
        <Navbar collapseOnSelect className='color-nav' expand={this.state.collapsesize}>
          <Container fluid>
            <Navbar.Brand as={Link} to="/">
              <img src={pandalogo} width="30" height="30" className="d-inline-block align-top" alt='' />
              <span className='navbarbrandname'>CHUYCHO</span>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${this.state.collapsesize}`} onClick={() => { this.setState({ showchanvas: true }) }}>
              <i className="bi bi-list" style={{ fontSize: '28px' }}></i>
            </Navbar.Toggle>
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${this.state.collapsesize}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${this.state.collapsesize}`}
              placement="end"
              show={this.state.showchanvas}
              onHide={() => this.closeOffCanvas()}
              restoreFocus={false}
              className='offcanvascss'
            >
              <Offcanvas.Header>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${this.state.collapsesize}`}>
                  CHUYCHO
                </Offcanvas.Title>
                <i className="fa-sharp fa-solid fa-xmark closebtnoffcanvas" onClick={() => this.closeOffCanvas()}></i>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  {/*<Link className="nav-link" to="/contact"></Link>*/}
                  <Nav.Link as={Link} to="/" onClick={() => this.closeOffCanvas()}>Home</Nav.Link>
                  <Nav.Link as={Link} to="/portafolio" onClick={() => this.closeOffCanvas()}>Portafolio</Nav.Link>
                  <Nav.Link as={Link} to="/AboutmeEN" onClick={() => this.closeOffCanvas()}>About</Nav.Link>
                  <NavDropdown title="Resume/CV" id={`offcanvasNavbarDropdown-expand-${this.state.collapsesize}`}>
                    <NavDropdown.Item as={Link} to="/AboutmeEN" onClick={() => this.closeOffCanvas()}>English</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/AboutmeEN" onClick={() => this.closeOffCanvas()}>Spanish</NavDropdown.Item>
                    <NavDropdown.Divider />

                    <Form.Range className='custom-range' value={this.state.bodybrightness} onChange={(e) => { this.changebodybrightness(e.target.value) }} onClick={() => this.closeOffCanvas()} />

                  </NavDropdown>
                  <NavDropdown title="Testings" id={`offcanvasNavbarDropdown-expand-${this.state.collapsesize}`} align="end">
                    <NavDropdown.Item as={Link} to="/aboutme" onClick={() => this.closeOffCanvas()}>Rain</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/works" onClick={() => this.closeOffCanvas()}>Flip Test</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/aboutme" onClick={() => this.closeOffCanvas()}>Another action</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/login" onClick={() => this.closeOffCanvas()}>Login</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/signup" onClick={() => this.closeOffCanvas()}>Sign Up</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item as={Link} to="/aboutme" onClick={() => this.closeOffCanvas()}>Something else here</NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link as={Link} to="/contact" onClick={() => this.closeOffCanvas()}>Contact Me</Nav.Link>
                  <Nav.Link onClick={() => { this.closeOffCanvas(); this.changeColorMode() }} className='iconchangemode'><i className='bi bi-moon-stars-fill'></i></Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      </>
    )
  }
}

export default Navbarcomp