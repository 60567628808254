import React, { Component, createRef } from 'react'
import { Card, Form, Button, Alert } from 'react-bootstrap'
import '../assets/css/Signup.css'
import { Link } from "react-router-dom";
import axios from 'axios';
import { store } from '../reduxstore/store'

export class Signup extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      setError: ""
    }

    this.emailRef = createRef();
    this.passwordRef = createRef();
    this.passwordConfirmRef = createRef();
    this.first_nameRef = createRef();
    this.last_nameRef = createRef();
  }

  render() {
    return (
      <>
        <div className='d-flex align-items-center justify-content-center bgpagesignup'>
          <div className='signupdivcard'>
            <Card>
              <Card.Body>
                <h2 className="text-center mb-4">Sign Up</h2>
                {(this.state.setError !== 'logged' && this.state.setError !== '') && <Alert variant="danger">{this.state.setError}</Alert>}
                {(this.state.setError === 'logged') && <Alert variant="success">Exito</Alert>}
                <Form onSubmit={(e) => { this.handleSubmit(e) }}>
                  <Form.Group id="firstname">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control type="text" ref={this.first_nameRef} required />
                  </Form.Group>
                  <Form.Group id="lastname">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control type="text" ref={this.last_nameRef} required />
                  </Form.Group>
                  <Form.Group id="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="text" ref={this.emailRef} required />
                  </Form.Group>
                  <Form.Group id="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" ref={this.passwordRef} required />
                  </Form.Group>
                  <Form.Group id="password-confirm">
                    <Form.Label>Password Confirmation</Form.Label>
                    <Form.Control type="password" ref={this.passwordConfirmRef} required />
                  </Form.Group>
                  <div style={{ marginTop: '20px' }}>
                    <Button disabled={this.state.loading} className="w-100 mt-100" type="submit">Sign Up</Button>
                  </div>
                </Form>
                <div className="w-100 text-center mt-4">
                  <span>Already have an account? </span>
                  <Link className='linksignup' to="/login">Log In</Link>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </>
    )
  }

  async handleSubmit(e) {
    e.preventDefault();

    if (this.passwordRef.current.value !== this.passwordConfirmRef.current.value) {
      return this.setState({ setError: 'Passwords do not match' })
    }

    try {
      const response = await axios.post('http://localhost:3001/auth/register', {
        first_name: this.first_nameRef.current.value,
        last_name: this.last_nameRef.current.value,
        user_email: this.emailRef.current.value,
        user_password: this.passwordRef.current.value
      });
      console.log(response.data);
      this.setState({ setError: 'logged' });
    } catch (error) {
      console.log(error);
      this.setState({ setError: error.toString() })
    }

  }

  componentDidMount() {      
    console.log(store.getState().csrfToken)
  }
}

export default Signup