import React, { Component } from 'react'
import '../assets/css/Works.css'

/*Images, SVG*/
import Computercode from '../assets/icons/Computercode.svg'
import Computercertificate from '../assets/icons/Computercertificate.svg'
import Computeruidesign from '../assets/icons/Computeruidesign.svg'

export class Works extends Component {
  render() {
    return (
      <div className="container pt-5">
        <div className="row pt-5">
          <div className="col-md-4 mt-5">
            <div className='hoverlettershadow'>
              <div className="content-inner fl-wrap">
                <div className="content-front">
                  <div className="cf-inner">
                    <div className="bg" style={{ backgroundImage: 'url(https://images.unsplash.com/photo-1481487196290-c152efe083f5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=830&q=80)' }} />
                    <div className="overlay" />
                    <div className="inner">
                      <h2>Web Design</h2>
                      <ul>
                        <li>Concept</li>
                        <li>Design</li>
                        <li>3D Modeling</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="content-back">
                  <div className="cf-inner">
                    <div className="inner">
                      <div className="dec-icon">
                        <img src={Computercode} height="100" alt='' />
                      </div>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas in pulvinar neque. Nulla finibus lobortis pulvinar.</p>
                      <div className="serv-price-wrap"><span>Price</span>$125-$335</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 mt-5">
            <div className='hoverlettershadow'>
              <div className="content-inner fl-wrap">
                <div className="content-front">
                  <div className="cf-inner">
                    <div className="bg " style={{ backgroundImage: 'url(https://images.unsplash.com/photo-1463171379579-3fdfb86d6285?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80)' }} />
                    <div className="overlay" />
                    <div className="inner">
                      <h2>Branding</h2>
                      <ul>
                        <li>Concept</li>
                        <li>Design</li>
                        <li>3D Modeling</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="content-back">
                  <div className="cf-inner">
                    <div className="inner">
                      <div className="dec-icon">
                        <img src={Computercertificate} height="100" alt='' />
                      </div>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas in pulvinar neque. Nulla finibus lobortis pulvinar.</p>
                      <div className="serv-price-wrap"><span>Price</span>$200-$500</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 mt-5">
            <div className='hoverlettershadow'>
              <div className="content-inner fl-wrap">
                <div className="content-front">
                  <div className="cf-inner">
                    <div className="bg" style={{ backgroundImage: 'url(https://images.unsplash.com/photo-1487017159836-4e23ece2e4cf?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=751&q=80)' }} />
                    <div className="overlay" />
                    <div className="inner">
                      <h2>Ui/Ux Design</h2>
                      <ul>
                        <li>Concept</li>
                        <li>Design</li>
                        <li>3D Modeling</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="content-back">
                  <div className="cf-inner">
                    <div className="inner">
                      <div className="dec-icon">
                        <img src={Computeruidesign} height="100" alt='' />
                      </div>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas in pulvinar neque. Nulla finibus lobortis pulvinar.</p>
                      <div className="serv-price-wrap"><span>Price</span>$225-$1300</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Works