import React, { Component } from 'react'
import '../assets/css/Loadingcomp.css'

export class Loadingcomp extends Component {
  render() {
    return (
      <>
        <div className="loadingbckgrnd loadingdarkbck" id='loadingcomp'>
          <div className="loadingtxtdiv">
            <span data-lightloader="C" className="ltr-loadingcomp">C</span>
            <span data-lightloader="H" className="ltr-loadingcomp">H</span>
            <span data-lightloader="U" className="ltr-loadingcomp">U</span>
            <span data-lightloader="Y" className="ltr-loadingcomp">Y</span>
            <span data-lightloader="C" className="ltr-loadingcomp">C</span>
            <span data-lightloader="H" className="ltr-loadingcomp">H</span>
            <span data-lightloader="O" className="ltr-loadingcomp">O</span>
          </div>
        </div>
      </>
    )
  }

  componentDidMount() {
    var preventscroll = document.getElementById('loadingcomp');
    preventscroll.addEventListener('touchmove', function (e) { e.preventDefault(); }, false);

    let test = localStorage.getItem('colormode');
    var ldingelement = document.getElementById("loadingcomp");

    // eslint-disable-next-line
    if (test == 'lightmode') {
      ldingelement.classList.add("loadinglightbck")
      ldingelement.classList.remove("loadingdarkbck");
    } else {
      ldingelement.classList.add("loadingdarkbck")
      ldingelement.classList.remove("loadinglightbck");
    }
  }

  componentWillUnmount() {
    var preventscroll = document.getElementById('loadingcomp');
    preventscroll.removeEventListener("touchmove", () => {this.nothing()}, false);
  }

  nothing(){
    console.log('eee')
  }

}

export default Loadingcomp