import React, { Component } from 'react'
import '../assets/css/Error404.css'

export class Error404 extends Component {

  constructor(props) {
    super(props);
    this.state = {
      lighton: false,
    }
  }

  switchinglight() {
    this.setState({ lighton: !this.state.lighton })
  }

  componentDidMount() {
    this.switchinglight()
  }

  render() {
    return (
      <div className='backgroundpage404' style={{ background: this.state.lighton ? '#181828' : 'white' }}>
        <div>{this.state.test}</div>
        <div>
          <div className="starsec" />
          <div className="starthird" />
          <div className="starfourth" />
          <div className="starfifth" />
        </div>
        {/*Dust particle end-*/}
        <div className="lamp__wrap">
          <div className="lamp">
            <div className="cable" />
            <div className="cover" />
            <div className="in-cover">
              <div className="bulb" />
            </div>
            <div className="light" />
          </div>
        </div>
        {/* END Lamp */}
        <section className="error">
          {/* Content */}
          <div className="error__content">
            <div className="error__message message">
              <h1 className="message__title">Page Not Found</h1>
              <p className="message__text">We're sorry, the page you were looking for isn't found here. The link you followed may either be broken or no longer exists. Please try again, or take a look at our.</p>
              <section className="persbtn404">
                <p className="ptagerrorpage" onClick={() => {this.switchinglight()}}></p>
              </section>
            </div>
          </div>
          {/* END Content */}
        </section>
      </div>
    )
  }
}

export default Error404