import CryptoJS from 'crypto-js';

const cipheralgorithm = CryptoJS.AES;
const password = process.env.REACT_APP_CRYPTOSECRETS;

function encrypt(obj) {
  return new Promise((resolve, reject) => {
    try {
      const key = CryptoJS.enc.Utf8.parse(password);
      const iv = CryptoJS.lib.WordArray.random(16);
      const ciphertext = cipheralgorithm.encrypt(CryptoJS.enc.Utf8.parse(JSON.stringify(obj)), key, {
        iv: iv,
        mode: CryptoJS.mode.CBC
      });
      resolve(iv.toString(CryptoJS.enc.Base64) + '$' + ciphertext.toString());
    } catch (error) {
      reject('Error Encrypt: ' +error)
    }
  })
}

function decrypt(text) {
  console.log(text)
  return new Promise((resolve, reject) => {
    try {
      const parts = text.split('$');
      const iv = CryptoJS.enc.Base64.parse(parts[0]);
      const ciphertext = CryptoJS.enc.Base64.parse(parts[1]);
      const key = CryptoJS.enc.Utf8.parse(password);
      const decrypted = cipheralgorithm.decrypt({ ciphertext: ciphertext }, key, { iv: iv });
      const plaintext = decrypted.toString(CryptoJS.enc.Utf8);
      resolve(JSON.parse(plaintext))
    } catch (error) {
      reject('Error decrypting: '+error)
    }

  })
}

export { encrypt, decrypt };