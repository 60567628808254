/*import { configureStore } from '@reduxjs/toolkit';

const initialState = {
  userId: '',
  email: '',
  picture: '',
  csrfToken: ''
};

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_USER_ID':
      return { ...state, userId: action.payload };
    case 'SET_EMAIL':
      return { ...state, email: action.payload };
    case 'SET_PICTURE':
      return { ...state, picture: action.payload };
    case 'SET_CSRF_TOKEN':
      return { ...state, csrfToken: action.payload };
    default:
      return state;
  }
}

const store = configureStore({ reducer: rootReducer });*/

import { createStore } from 'redux';

const initialState = {
  userdata: '',
  csrfToken: ''
};

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_USER_DATA':
      return { ...state, userdata: action.payload };
    case 'SET_CSRF_TOKEN':
      return { ...state, csrfToken: action.payload };
    default:
      return state;
  }
}

export const store = createStore(rootReducer);