import React, { Component } from 'react';
import '../assets/css/Home.css';
import { Link } from "react-router-dom";
import { ReactComponent as HexagonLight } from '../assets/icons/HexagonLight.svg';
import { ReactComponent as HexagonDark } from '../assets/icons/HexagonDark.svg';

export class Home extends Component {
  state = {
    HexaDarkMode: true,
  };

  render() {
    return (
      <>
        <div className="pt-table">
          <div className="pt-tablecell page-home">
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-md-offset-1 col-md-10 col-lg-offset-2mod col-lg-8mod">
                  <div className="mb-5 text-center">
                    <p className="home_title" style={{ '--hometxtinfo': '#2B303A' }}> Welcome to My Page</p>
                    <p className="mt20 home_subtitle" style={{ '--hometxtinfo': '#2B303A' }}>Under Development. When I have time I work on it otherwise I'm just procrastinating on whatever I'm doing. Actually just me lazy as always... Soon on VueJS & AngularJS</p>
                  </div>
                  {/* clock
                  <div>
                    <div className="divclock mb-5" style={{ margin: 'auto' }}>
                      <h1 id="time" className="time">3:47 AM</h1>
                      <h1 id="date" className='date'>3:47 AM</h1>
                      <h1 id="greeting" className='greetings'>good evening</h1>
                    </div>
                  </div>
                  */}
                  <div className="hexagon-menu cleardivbelowhome">
                    <div className="hexagon-item">
                      <div className="hex-item">
                        <div className='hexbckhome' />
                        <div className='hexbckhome' />
                        <div className='hexbckhome' />
                      </div>
                      <div className="hex-item">
                        <div className='hexbckhome' />
                        <div className='hexbckhome' />
                        <div className='hexbckhome' />
                      </div>
                      <Link className="hex-content" style={{ '--hexcontenttxtcolor': '#e0e0e0' }} onClick={() => {this.testcors()}}>
                        <span className="hex-content-inner">
                          <span className="icon">
                            <i className="fa-solid fa-universal-access" />
                          </span>
                          <span className="title">Welcome</span>
                        </span>
                        {this.state.HexaDarkMode ? <HexagonLight /> : <HexagonDark />}
                      </Link>
                    </div>
                    <div className="hexagon-item">
                      <div className="hex-item">
                        <div className='hexbckhome' />
                        <div className='hexbckhome' />
                        <div className='hexbckhome' />
                      </div>
                      <div className="hex-item">
                        <div className='hexbckhome' />
                        <div className='hexbckhome' />
                        <div className='hexbckhome' />
                      </div>
                      <Link className="hex-content" to='/AboutmeEN' style={{ '--hexcontenttxtcolor': '#e0e0e0' }}>
                        <span className="hex-content-inner">
                          <span className="icon">
                            <i className="fa fa-bullseye" />
                          </span>
                          <span className="title">About</span>
                        </span>
                        {this.state.HexaDarkMode ? <HexagonLight /> : <HexagonDark />}
                      </Link>
                    </div>
                    <div className="hexagon-item">
                      <div className="hex-item">
                        <div className='hexbckhome' />
                        <div className='hexbckhome' />
                        <div className='hexbckhome' />
                      </div>
                      <div className="hex-item">
                        <div className='hexbckhome' />
                        <div className='hexbckhome' />
                        <div className='hexbckhome' />
                      </div>
                      <Link className="hex-content" style={{ '--hexcontenttxtcolor': '#e0e0e0' }}>
                        <span className="hex-content-inner">
                          <span className="icon">
                            <i className="fa fa-braille" />
                          </span>
                          <span className="title">Services</span>
                        </span>
                        {this.state.HexaDarkMode ? <HexagonLight /> : <HexagonDark />}
                      </Link>
                    </div>
                    <div className="hexagon-item">
                      <div className="hex-item">
                        <div className='hexbckhome' />
                        <div className='hexbckhome' />
                        <div className='hexbckhome' />
                      </div>
                      <div className="hex-item">
                        <div className='hexbckhome' />
                        <div className='hexbckhome' />
                        <div className='hexbckhome' />
                      </div>
                      <Link className="hex-content" to='/AboutmeEN' style={{ '--hexcontenttxtcolor': '#e0e0e0' }}>
                        <span className="hex-content-inner">
                          <span className="icon">
                            <i className="fa fa-id-badge" />
                          </span>
                          <span className="title">Resume/CV</span>
                        </span>
                        {this.state.HexaDarkMode ? <HexagonLight /> : <HexagonDark />}
                      </Link>
                    </div>
                    <div className="hexagon-item">
                      <div className="hex-item">
                        <div className='hexbckhome' />
                        <div className='hexbckhome' />
                        <div className='hexbckhome' />
                      </div>
                      <div className="hex-item">
                        <div className='hexbckhome' />
                        <div className='hexbckhome' />
                        <div className='hexbckhome' />
                      </div>
                      <Link className="hex-content" style={{ '--hexcontenttxtcolor': '#e0e0e0' }}>
                        <span className="hex-content-inner">
                          <span className="icon">
                            <i className="fa fa-life-ring" />
                          </span>
                          <span className="title">Portafolio</span>
                        </span>
                        {this.state.HexaDarkMode ? <HexagonLight /> : <HexagonDark />}
                      </Link>
                    </div>
                    <div className="hexagon-item">
                      <div className="hex-item">
                        <div className='hexbckhome' />
                        <div className='hexbckhome' />
                        <div className='hexbckhome' />
                      </div>
                      <div className="hex-item">
                        <div className='hexbckhome' />
                        <div className='hexbckhome' />
                        <div className='hexbckhome' />
                      </div>
                      <Link className="hex-content" to='/homev2' style={{ '--hexcontenttxtcolor': '#e0e0e0' }}>
                        <span className="hex-content-inner">
                          <span className="icon">
                            <i className="fa fa-clipboard" />
                          </span>
                          <span className="title">HomeV2</span>
                        </span>
                        {this.state.HexaDarkMode ? <HexagonLight /> : <HexagonDark />}
                      </Link>
                    </div>
                    <div className="hexagon-item">
                      <div className="hex-item">
                        <div className='hexbckhome' />
                        <div className='hexbckhome' />
                        <div className='hexbckhome' />
                      </div>
                      <div className="hex-item">
                        <div className='hexbckhome' />
                        <div className='hexbckhome' />
                        <div className='hexbckhome' />
                      </div>
                      <Link className="hex-content" to='/contact' style={{ '--hexcontenttxtcolor': '#e0e0e0' }}>
                        <span className="hex-content-inner">
                          <span className="icon">
                            <i className="fa fa-map-signs" />
                          </span>
                          <span className="title">Contact</span>
                        </span>
                        {this.state.HexaDarkMode ? <HexagonLight /> : <HexagonDark />}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  componentDidMount() {
    this.changesonstorage();
    window.addEventListener("changecolorevent", this.changesonstorage, false);

    /*setInterval(() => {
      this.clockfunction()
    }, 1000);*/

  }
  componentWillUnmount() {
    window.removeEventListener("changecolorevent", this.changesonstorage, false);

    /*const areaListener = new AbortController();

    area.addEventListener(
      `click`,
      ({ clientX: x, clientY: y }) => {
        app.addSpot(x, y);
        app.addFlag = 1;
      },
      { signal: areaListener.signal }
    );

    areaListener.abort()*/
  }

  changesonstorage = () => {
    let test = localStorage.getItem('colormode');
    let allhexagons = document.querySelectorAll(".hex-content");
    let allhexabck = document.querySelectorAll(".hexbckhome");
    let allinfohome = document.querySelectorAll(".home_title, .home_subtitle");

    // eslint-disable-next-line
    if (test == 'lightmode') {
      this.setState({ HexaDarkMode: false });
      allhexagons.forEach(hexagon => {
        hexagon.style.setProperty('--hexcontenttxtcolor', '#2B303A')
      })
      allinfohome.forEach(txt => {
        txt.style.setProperty('--hometxtinfo', 'aliceblue')
      })
      allhexabck.forEach(back => {
        back.style.setProperty('--homebackhexa', '#2B303A')
      })
    } else {
      this.setState({ HexaDarkMode: true });
      allhexagons.forEach(hexagon => {
        hexagon.style.setProperty('--hexcontenttxtcolor', '#e0e0e0')
      })
      allinfohome.forEach(txt => {
        txt.style.setProperty('--hometxtinfo', '#2B303A')
      })
      allhexabck.forEach(back => {
        back.style.setProperty('--homebackhexa', '#1e2530')
      })
    }
  }

  clockfunction() {
    let today = new Date();
    let time = today.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    let hours = today.getHours();
    let month = today.getMonth();
    let day = today.getUTCDate();
    let year = today.getFullYear();

    let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    document.getElementById('time').innerHTML = time;
    document.getElementById('date').innerHTML = months[month] + " " + day + ", " + year;

    function displayGreeting() {
      if (hours < 11) {
        return 'GOOD MORNING';
      } if (hours > 17) {
        return 'GOOD EVENING';
      } else {
        return 'GOOD AFTERNOON';
      }
    }

    document.getElementById('greeting').innerHTML = displayGreeting();
  }


  testcors() {
    console.log('hola')
    fetch("http://localhost:3001/test").then(req => req.text()).then(console.log)
  }

}

export default Home